.layout-container {
    height: 100vh;
    min-width: 1440px;
    background: #fff;
    position: relative;
    padding-left: 240px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        border-radius: 0 0 0 0;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #a8a8a8;
        border-radius: 6px;
    }
    .header_nav {
        position: fixed;
        left: 0;
        top: 0;
        height: 100vh;
        width: 240px;
        background-color: #f9f9f9 !important;
        z-index: 999;
        padding: 65px 24px 78px 24px;
        display: flex;
        flex-direction: column;
        background-image: url('../assets/img/nav-bg.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: 100px;
        background-position-x: 4px;
        .logo {
            width: 127px;
            height: 100px;
            // font-size: 32px;
            // font-family: var(--primary-semiBold-font);
            // font-weight: normal;
            // color: #097dff;
            // line-height: 36px;
            margin:0 0 74px 25px;
        }
        .menu_items {
            width: 100%;
            background: transparent;
            .menu_icon {
                width: 24px;
                height: 24px;
                // background-color: #097dff;
                background-repeat: no-repeat !important;
                background-size: contain !important;
                margin-right: 10px;
            }
            .menu_item {
                line-height: 24px;
                font-size: 16px;
                font-family: Inter-SemiBold;
                color: #222222;
                margin-bottom: 35px;
                padding: 15px 10px 15px 32px;
                cursor: pointer;
                transition: all 0.1s;
                font-weight: normal;
                position: relative;
                user-select: none;
                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 12px;
                    height: 12px;
                    background: #FF7957;
                    border-radius: 50%;
                    opacity: 0;
                }
                &:hover {
                    opacity: 0.8;
                }
                &.active {
                    color: #FF7957;
                    font-family: Inter-Bold;
                    font-weight: bold;
                    &::before{
                        opacity: 1;
                    }
                }
            }
        }
        .logout_btn {
            min-height: 40px;
            margin: auto auto 0 auto;
            width: 120px;
            height: 40px;
            border-radius: 6px;
            border: 1px solid #FF7957;
            background-color: #FF7957;
            font-size: 14px;
            font-family: var(--primary-medium-font);
            font-weight: 500;
            color: #fff;
            &:hover{
                border: 1px solid #FF7957;
                color: #fff;
                background-color: #FF7957;
            }
        }
    }
    .layout-bottom {
        // margin: 40px;
        // @media only screen and (max-width: 1500px) {
        //     margin: 40px;
        // }
        .ant-layout {
            background-color: #fff !important;
        }
    }
}
