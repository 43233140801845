/*
 * @author        r.shouwei <r.shouwei@technehq.com>
 * @date          2023-05-08 10:37:09
 * Copyright © Techne All rights reserved
 */
.r-left {
    display: flex;
    align-items: center;
}
.r-top {
    display: flex;
    align-items: flex-start;
}
.r-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.marLeft {
    margin-left: auto;
}
.r-input {
    height: 48px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #E4E4E4;
    font-size: 14px;
    font-family: var(--primary-light-font);
    font-weight: 500;
    color: #222222;
    padding-left: 16px;
    .ant-input {
        &::placeholder {
            font-size: 14px;
            font-family: var(--primary-light-font);
            font-weight: 500;
            color: #9D9D9D;
        }
        font-size: 14px;
        font-family: var(--primary-light-font);
        font-weight: 500;
        color: #222222;
    }
    &:disabled{
        background-color: #f9f9f9 !important;
        color: #9D9D9D;
    }
}

.r-input_serach {
    padding-left: 10px;
    height: 48px;
    border-radius: 10px;
    border: 1px solid #E4E4E4;
    svg {
        font-size: 18px;
        color: #a3a3a3;
    }
    .ant-input-suffix {
        align-items: center;
        .ant-input-clear-icon {
            width: 20px;
            height: 20px;
        }
    }
    .ant-input::placeholder {
        font-size: 14px;
        color: #9D9D9D !important;
    }
}
.r-textarea {
    border-radius: 10px;
    border: 1px solid #dbdbdb;
    position: relative;
    padding-bottom: 28px;
    .ant-input {
        border-radius: 10px;
        border: none;
        box-shadow: none;
        font-size: 15px;
        font-family: var(--primary-light-font);
        font-weight: 500;
        color: #1b1b1b;
    }
    .ant-input-data-count {
        position: absolute;
        bottom: 3px;
        right: 10px;
        margin-bottom: 0;
        font-size: 13px;
        font-family: var(--primary-light-font);
        font-weight: 500;
        color: #a3a3a3;
    }
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-track {
        background-color: #f1f1f1;
        border-radius: 0 10px 0 0;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #a8a8a8;
        border-radius: 6px;
    }
}
.r-input_autoComplate {
    width: 100%;
    height: 48px;
    .ant-select-selector {
        height: 48px !important;
        border-radius: 4px 4px 4px 4px !important;
        padding-left: 20px !important;
        border: 1px solid #dbdbdb !important;
        .ant-select-selection-search {
            height: 48px;
            display: flex;
            align-items: center;
            left: 20px;
            .ant-select-selection-search-input {
                font-size: 13px;
                font-family: Montserrat-Medium, Montserrat;
                font-weight: 500;
                color: #1b1b1b;
            }
        }
        .ant-select-selection-placeholder {
            display: flex;
            align-items: center;
        }
    }
}
.r-switch {
    height: 31px;
    width: 51px;
    background: #ffffff;
    border: 1px solid rgba(16, 16, 16, 0.5);
    &:hover {
        background: #ffffff;
    }
    &:hover:not(.ant-switch-disabled) {
        background: #ffffff;
    }
    .ant-switch-handle {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.3);
        &::before {
            border-radius: 50%;
            background: #101010;
            // top: -1px;
        }
    }
    &.ant-switch-checked {
        border: 1px solid #299fff;
        background: #299fff;
        .ant-switch-handle {
            inset-inline-start: calc(100% - 27px);
            &::before {
                border-radius: 50%;
                background: #ffffff;
            }
        }
    }
}
.search_icon {
    width: 20px;
    height: 20px;
    background: url('../assets/img/search.png') no-repeat;
    background-size: contain;
}
.clear_icon {
    width: 20px;
    height: 20px;
    background: url('../assets/img/clear.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.r-select {
    height: max-content;
    .ant-select-selector {
        height: 56px !important;
        background: #ffffff;
        border-radius: 10px;
        border: 1px solid #dbdbdb !important;
        .ant-select-selection-item {
            line-height: 56px;
            font-size: 15px;
            font-family: var(--primary-light-font);
            font-weight: 500;
            color: #1b1b1b;
        }
    }
    .ant-select-selection-placeholder {
        height: 56px !important;
        line-height: 56px !important;
        font-size: 15px;
        font-family: var(--primary-light-font);
        font-weight: 400;
        color: #a3a3a3;
    }
}
.r-select48 {
    .ant-select-selector {
        height: 48px !important;
        .ant-select-selection-item {
            line-height: 48px;
        }
    }
    .ant-select-selection-placeholder {
        height: 48px !important;
        line-height: 48px !important;
    }
}
.r-input_sel {
    display: flex;
    align-items: center;
    svg {
        font-size: 20px;
        color: #d1d1d1;
    }
    .ant-select-clear {
        align-items: center;
        width: 20px;
        height: 20px;
        top: 41%;
        .ant-input-clear-icon {
            width: 20px;
            height: 20px;
        }
    }
}
.sel_arrow_icon {
    width: 24px;
    height: 24px;
    background: url('../assets/img/chevron-down.png') no-repeat;
    background-size: contain;
}
.r-select_mini {
    .ant-select-selector {
        height: 32px !important;
        .ant-select-selection-item {
            line-height: 30px;
            font-size: 13px;
            font-family: Montserrat-Medium, Montserrat;
            font-weight: 500;
            color: #1b1b1b;
        }
    }
}
.r-datePicker,.r-downTimePick {
    height: 48px;
    background: #ffffff;
    border-radius: 8px;
    padding: 0 16px;
    border: 1px solid #e1e1e1;
    .sel_time_icon {
        width: 20px;
        height: 20px;
        background: url('../assets/img/date.png') no-repeat;
        background-size: contain;
    }
    &::before {
        content: '';
        min-width: 21px;
        height: 21px;
        background: url('../assets/img/date.png') no-repeat;
        background-size: contain;
        margin: 0 13px 0 0;
    }
    .ant-picker-clear {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ant-picker-input>input {
        text-align: left;
        font-size: 14px;
        font-family: var(--primary-light-font);
        color: #222222;
        &::placeholder {
            font-size: 14px;
            font-family: Inter-Regular;
            font-weight: normal;
            color: #9D9D9D !important;
            // color: #d6d6d6 !important;
            opacity: 1;
        }
    }
    .ant-picker-range-separator {
        // display: none;
        opacity: 0;
        display: flex;
        align-items: center;
        position: relative;
        .ant-picker-separator {
            display: none;
        }
        &::after {
            content: '';
            position: absolute;
            left: -14px;
            width: 6px;
            height: 2px;
            background: #a4a4a4;
        }
    }
    .ant-picker-active-bar {
        display: none;
        margin-inline-start: 30px !important;
    }
}
.r-downTimePick{
    width: 100%;
    &::before{
        display: none;
    }
}
.r-date_showLine {
    .ant-picker-range-separator {
        opacity: 1;
    }
}
.r-modal {
    width: 480px !important;
    .modal-top-title {
        font-size: 24px;
        font-family: 'Inter-Bold';
        font-weight: bold;
        color: #222222;
        text-align: center;
        // border-bottom: 1px solid #f6f6f6;
    }
    .ant-modal-close {
        right: 30px;
        top: 30px;
        width: 28px;
        height: 28px;
        display: none;
        .close-icon {
            width: 24px;
            height: 24px;
            margin-top: 3px;
        }
    }
    .modal-top-tips {
        font-size: 16px;
        font-family: var(--primary-light-font);
        font-weight: 400;
        color: #222222;
        line-height: 24px;
        margin: 40px 0 30px 0;
    }
    .ant-modal-content {
        border-radius: 20px !important;
        padding: 30px !important;
        box-shadow: 0px 6px 19px 0px rgba(0, 0, 0, 0.12);
    }
}
.r-table {
    .ant-table-thead {
        .ant-table-cell {
            padding: 22px 16px;
            font-size: 14px;
            font-family: var(--primary-light-font);
            font-weight: 400;
            color: #9D9D9D;
            background-color: #F5F5F5;
            border-radius: 0 !important;
            &::before {
                display: none;
            }
            // border-bottom: 2px solid #f6f6f6;
        }
    }
    .r-table_row,
    .ant-table-row {
        &::hover {
            // background-color: #f1f3f5;
            background-color: #f9f9f9;
        }
        .ant-table-cell {
            padding: 25px 16px;
            font-size: 14px;
            font-family: var(--primary-light-font);
            font-weight: 400;
            color: #222222;
            transition: all 0.2s;
            white-space: pre-wrap;
        }
    }
    .hideBorder > .ant-table-cell {
        border-bottom: 1px solid #f3f3f3;
    }
    .ant-table-placeholder {
        .ant-table-cell {
            border: none;
        }
    }
    &.click_row {
        .r-table_row,
        .ant-table-row {
            cursor: pointer;
        }
    }
}
.pointer_table .r-table_row .ant-table-cell {
    cursor: pointer;
}
.initial_table .r-table_row .ant-table-cell {
    cursor: initial !important;
}
.r-default_btn,
.r-defaultBlick_btn,
.r-primary_btn,
.r-emBorder_btn {
    height: 44px;
    background: #FF7957;
    border-radius: 6px;
    opacity: 1;
    border: 1px solid #FF7957;
    font-size: 17px;
    font-family: var(--primary-medium-font);
    font-weight: 500;
    color: #ffffff;
    box-shadow: none;
    &:hover {
        border: 1px solid #FF7957;
        color: #ffffff;
        background: #FF7957 !important;
    }
}
.r-defaultBlick_btn {
    border: 1px solid #151515;
    color: #151515;
    &:hover {
        border-color: #151515 !important;
        color: #151515 !important;
        background-color: #ffffff !important;
    }
}
.r-primary_btn {
    border: 1px solid #FF7957;
    color: #fff;
    background-color: #FF7957;
    font-size: 14px;
    &:disabled,&.disabled  {
        border: 1px solid #FF7957;
        color: #fff;
        background-color: #FF7957;
        cursor: not-allowed;
        opacity: 0.4;
    }
    &:hover {
        border: 1px solid #FF7957;
        color: #fff !important;
        background-color: #FF7957;
    }
}
.r-emBorder_btn {
    border: 1px solid #FF7957;
    color: #FF7957;
    background-color: #ffffff;
    font-size: 14px;
    &:disabled,&.disabled {
        border: 1px solid #FF7957;
        color: #FF7957;
        background-color: #ffffff;
        cursor: not-allowed;
        opacity: 0.4;
    }
    &:hover {
        border: 1px solid #FF7957 !important;
        color: #FF7957 !important;
        background-color: #ffffff !important;
    }
}
.r-derger_btn,
.r-dergerMedium_btn,
.r-dergerDefault_btn {
    width: 145px;
    height: 42px;
    background: #F30A0A;
    border: 1px solid #F30A0A;
    border-radius: 10px;
    font-size: 14px;
    font-family: var(--primary-medium-font);
    font-weight: 500;
    color: #ffffff;
    &:hover {
        color: #ffffff !important;
        background: #ff7878 !important;
        border-color: #F30A0A !important;
    }
}
.r-dergerMedium_btn {
    height: 56px;
    font-size: 17px;
}
.r-dergerDefault_btn {
    background-color: #ffffff;
    color: #fc5555;
    &:hover {
        color: #fc5555 !important;
        background: #ffffff !important;
        border-color: #fc5555 !important;
    }
}
.r-success_btn {
    width: 145px;
    height: 42px;
    background: #fff;
    border: 1px solid #29cc6a;
    border-radius: 12px 12px 12px 12px;
    font-size: 14px;
    font-family: var(--primary-medium-font);
    font-weight: 500;
    color: #29cc6a;
    &:hover {
        color: #29cc6a !important;
        background: #fff !important;
        border-color: #29cc6a !important;
    }
}
.modal_form {
    position: relative;
    .r-slider {
        position: absolute;
        bottom: 180px;
        left: 110px;
    }
}
// table cell
.user_statistics_list,
.table_main {
    .user_info {
        .user_img {
            width: 25px;
            min-width: 25px;
            height: 25px;
            border-radius: 25px 25px 25px 25px;
            opacity: 1;
            // background-color: #5877f9;
            object-fit: cover;
            margin-right: 10px;
        }
        .user_img_text {
            width: 25px;
            height: 25px;
            border-radius: 25px 25px 25px 25px;
            background-color: #5877f9;
            font-size: 14px;
            font-family: Montserrat-SemiBold, Montserrat;
            font-weight: 600;
            color: #ffffff;
            margin-right: 10px;
            text-align: center;
            line-height: 25px;
        }
    }
}
.user_img {
    min-width: 25px;
}
.primary_tip {
    font-size: 14px;
    font-family: var(--primary-light-font);
    font-weight: 500;
    color: #222222;
    line-height: 20px;
}
.form_tip {
    font-size: 14px;
    font-family: var(--primary-medium-font);
    font-weight: 500;
    color: #222222;
    line-height: 20px;
    margin: 16px 0 8px 0;
}
.hide_css {
    display: none !important;
}
.oneLine_text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.twoLine_text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
// new
.ant-form-item-label {
    > label {
        color: #222 !important;
        font-size: 15px !important;
        // font-family: var(--primary-light-font);
        font-family: var(--primary-medium-font);
        font-weight: 500;
        width: 100%;
    }
}
.ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: '';
}
.numInputs {
    display: flex;
    align-items: center;
    justify-content: center;
    input {
        width: 80px;
        height: 80px;
        background: #ffffff;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        border: 1px solid #e1e1e1;
        text-align: center;
        margin-right: 20px;
        font-size: 23px;
        font-family: var(--primary-light-font);
        font-weight: 500;
        color: #1b1b1b;
        &:focus {
            // border: 1px solid #ffd952;
            box-shadow: 0 0 0 2px rgba(5, 175, 255, 0.1);
            outline: none;
        }
    }
}
.resend_btn {
    cursor: pointer;
    height: 24px;
    font-size: 16px;
    font-family: var(--primary-medium-font);
    font-weight: 500;
    color: #097dff;
    line-height: 24px;
    user-select: none;
    &.disabled {
        cursor: no-drop;
        opacity: 0.8;
    }
}
.back_box {
    cursor: pointer;
    font-size: 16px;
    font-family: PlusJakartaSans-Regular;
    font-weight: 400;
    color: #222222;
    .back_icon {
        width: 18px;
        height: 18px;
        cursor: pointer;
        margin-right: 8px;
        background-image: url('../assets/img/back.png');
        background-size: contain;
    }
}
.table_page {
    height: calc(100vh);
    display: flex;
    flex-direction: column;
}
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.flex-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.flex-warp {
    display: flex;
    flex-wrap: wrap;
}
img {
    width: 100%;
    height: 100%;
    vertical-align: baseline;
}
.cell-txt {
    font-size: 14px;
    font-family: var(--primary-light-font);
    font-weight: 400;
    color: #222222;
    transition: all 0.2s;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
}
.table_emptyBox {
    font-size: 14px;
    font-family: var(--primary-light-font);
    width: 100%;
    text-align: center;
    // margin-top: 200px;
    margin: 100px 0;
    font-weight: 400;
    color: #a3a3a3;
    line-height: 16px;
}
.r-input_password {
    .anticon-eye,
    .anticon-eye-invisible {
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
        background-image: url('../assets/img/eye.png');
        svg {
            display: none;
        }
    }
    .anticon-eye-invisible {
        background-image: url('../assets/img/eye-slash.png');
    }
}
.ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: '';
}
.ant-form-item {
    margin-bottom: 12px;
    .ant-form-item-explain-error {
        display: none;
    }
    .ant-input-affix-wrapper-status-error {
        .ant-input-prefix {
            color: #141414 !important;
        }
    }
    &.ant-form-item-has-error {
        .r-input,
        .ant-input-affix-wrapper {
            border-color: #e1e1e1 !important;
            &:focus {
                box-shadow: 0 0 0 2px rgba(5, 175, 255, 0.1) !important;
            }
        }
        .ant-input-affix-wrapper-focused {
            box-shadow: 0 0 0 2px rgba(5, 175, 255, 0.1) !important;
        }
    }
}
// :where(.css-1o2jgmg).ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
//     border-color: #ff4d4f;
// }

.noMessage_box{
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    color: #9D9D9D;
    line-height: 20px;
    width: 100%;
    text-align: center;
}
.delete_icon{
    display: block;
    width: 24px;
    height: 24px;
    background: url('../assets/img/delete.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.radio_icon{
    width: 12px;
    height: 12px;
    background: url('../assets/img/radio.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.radioChecked_icon{
    width: 12px;
    height: 12px;
    background: url('../assets/img/radio-checked.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.more_icon{
    display: block;
    width: 20px;
    height: 20px;
    background: url('../assets/img/more.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.chevron-down_icon{
    display: block;
    width: 20px;
    height: 20px;
    background: url('../assets/img/chevron-down.png') no-repeat;
    background-size: contain;
    cursor: pointer;
}
.ant-popover-inner{
    padding: 13px 20px !important;
}
.tableOpeation-box{
    .tb_item{
        font-family: PlusJakartaSans-Regular;
        font-weight: 400;
        font-size: 15px;
        color: #222222;
        line-height: 24px;
        text-align: left;
        padding: 7px 0;
        cursor: pointer;
        user-select: none;
        &.danger{
            color: #F30A0A;
        }
        &.success{
            color: #3ECC92;
        }
    }
}
.prize_pop{
    width: 420px;
    .ant-popover-inner{
        border: 1px solid #E4E4E4;
        box-shadow: none;
        padding: 0 !important;
        max-height: 250px;
        overflow-y: auto;
        .prizePop_name{
            width: 100%;
            height: 40px;
            background: #FFFFFF;
            font-family: Inter-Regular, Inter;
            font-weight: 400;
            font-size: 14px;
            color: #222222;
            padding: 0 16px;
            line-height: 40px;
            cursor: pointer;
            &:first-child{
                border-radius: 8px 8px 0 0;
            }
            &:last-child{
                border-radius: 0 0 8px 8px;
            }
            &:hover{
                background-color: #f2f2f2 !important;
            }
        }
    }
}
// icon upload
.uploadMain_box{
    align-items: flex-end;
    .upload_imgBox {
        background: #ffffff;
        position: relative;
        min-width: 120px;
        height: 120px;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #E4E4E4;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        #imgInput {
            position: absolute;
            z-index: -999;
            opacity: 0;
        }
    }
    .upload_tip{
        margin-left: 10px;
        font-family: Inter-Regular, Inter;
        font-weight: 400;
        font-size: 12px;
        color: #9D9D9D;
        line-height: 16px;
        text-align: left;
    }
}