.project_main {
    h2 {
        font-size: 32px;
        font-family: var(--primary-semiBold-font);
        font-weight: normal;
        color: #151515;
        line-height: 38px;
    }
    .workout_top {
        margin: 60px 0 30px 0;
        .space15 {
            width: 15px;
            height: 100%;
        }
        .total-box{
            font-family: Inter-Medium, Inter;
            font-weight: 500;
            font-size: 14px;
            color: #222222;
            margin-left: auto;
            .price-tip{
                font-family: Inter-Bold;
                font-weight: bold;
                font-size: 20px;
                margin-left: 10px;
            }
        }
    }
}