/*
 * @author        r.shouwei <r.shouwei@technehq.com>
 * @date          2023-05-09 16:00:00
 * Copyright © Techne All rights reserved
 */
.user_main {
    h2 {
        font-size: 32px;
        font-family: var(--primary-semiBold-font);
        font-weight: normal;
        color: #151515;
        line-height: 38px;
    }
    .user_top {
        margin: 50px 0 30px 0;
        .space15 {
            width: 15px;
            height: 100%;
        }
    }
}
//user detail
.user_detailMain {
    .user_info {
        margin-top: 40px;
        width: 100%;
        padding: 30px;
        position: relative;
        background: #F9F9F9;
        border-radius: 10px 10px 10px 10px;
        .user_name {
            font-size: 32px;
            font-family: var(--primary-bold-font);
            font-weight: bold;
            color: #222222;
            line-height: 40px;
        }
        .user_statusBox {
            width: 118px;
            height: 28px;
            background: #3ECC92;
            border-radius: 20px;
            font-size: 14px;
            font-family: var(--primary-medium-font);
            color: #ffffff;
            cursor: pointer;
            user-select: none;
            &.suspended {
                background: #F30A0A;
            }
        }
        .exchange_icon{
            width: 20px;
            height: 20px;
            background: url('../../../../assets/img/exchange.png') no-repeat;
            background-size: contain;
            margin-left: 5px;
            cursor: pointer;
        }
        .userInfo_msg{
            margin-top: 20px;
            .userInfo-box{
                font-family: PlusJakartaSans-Medium;
                font-weight: 500;
                font-size: 16px;
                color: #222222;
                line-height: 24px;
                text-align: left;
                flex: 1;
                &:last-child{
                    margin-left: 20px;
                }
            }
            .usermarb12{
                margin-bottom: 12px;
            }
        }
    }
    .user-statistice{
        .top_tips {
            margin: 40px 0 20px 0;
            font-size: 20px;
            font-family: PlusJakartaSans-SemiBold;
            font-weight: 600;
            color: #222222;
            line-height: 24px;
        }
        .statistics_top {
            .statistics_item {
                flex: 1;
                height: 126px;
                background: #ffffff;
                border-radius: 10px;
                border: 1px solid #E4E4E4;
                padding: 20px 23px;
                &:nth-child(2) {
                    margin: 0 10px;
                }
                .statistics_item_label {
                    font-size: 14px;
                    font-family: PlusJakartaSans-SemiBold;
                    font-weight: 600;
                    color: #222222;
                    margin-bottom: 19px;
                }
                .statistics_item_value {
                    width: 100%;
                    text-align: center;
                    font-size: 30px;
                    font-family: PlusJakartaSans-SemiBold;
                    font-weight: 600;
                    color: #222222;
                }
            }
        }
        .category-list{
            flex-wrap: wrap;
            .category-item{
                min-width: 173px;
                height: 48px;
                background: #FFFFFF;
                border-radius: 8px 8px 8px 8px;
                border: 1px solid #E4E4E4;
                padding: 12px;
                font-family: PlusJakartaSans-SemiBold;
                font-weight: 600;
                font-size: 14px;
                color: #222222;
                line-height: 20px;
                margin:0 12px 12px 0;
                .category_icon{
                    width: 24px;
                    height: 24px;
                    margin-right: 10px;
                    border-radius: 4px;
                }
            }
        }
        .more-box{
            font-family: Inter-Bold, Inter;
            font-weight: bold;
            font-size: 12px;
            color: #FF7957;
            cursor: pointer;
            user-select: none;
        }
        .userPrize-box{
            width: 100%;
            background: #FFFFFF;
            border-radius: 10px 10px 10px 10px;
            border: 1px solid #E4E4E4;
            padding: 0 20px 10px 20px;
            .prize-itemHeader{
                padding: 18px 0;
                border-bottom: 1px solid #E4E4E4;
                font-family: PlusJakartaSans-Medium;
                font-weight: 500;
                font-size: 14px;
                color: #9D9D9D;
                line-height: 20px;
                margin-bottom: 8px;
            }
            .prize-item{
                font-family: PlusJakartaSans-Medium;
                font-weight: 500;
                font-size: 14px;
                color: #222222;
                line-height: 20px;
                font-style: normal;
                padding: 8px 0;
            }
            .w200{
                width: 200px;
                min-width: 200px;
            }
            .w150{
                width: 150px;
                min-width: 150px;
            }
        }
    }
}
.modal_tips {
    font-size: 16px;
    font-family: Inter-SemiBold;
    font-weight: normal;
    color: #222222;
    line-height: 24px;
    width: 100%;
    text-align: center;
}
