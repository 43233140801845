.survey_main {
    h2 {
        font-size: 32px;
        font-family: var(--primary-semiBold-font);
        font-weight: normal;
        color: #151515;
        line-height: 38px;
    }
    .survey_top {
        margin: 50px 0;
        .space15 {
            width: 15px;
            height: 100%;
        }
        .categorytit_box{
            font-family: PlusJakartaSans-Medium;
            font-weight: 500;
            font-size: 18px;
            color: #222222;
            line-height: 24px;
            text-align: left;
            .categorytit_tip{
                font-family: Inter-Regular, Inter;
                font-weight: 400;
                font-size: 14px;
                color: #9D9D9D;
                line-height: 20px;
                margin-top: 8px;
            }
        }
    }
}

.categorycard_list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 25px 30px;
    max-width: 1367px;
    overflow: hidden;
    .category_card{
        // flex: 0 0 calc(33.3333% - 17px);
        // width: 350px;
        max-width: 430px;
        height: 86px;
        background: #FFFFFF;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #E4E4E4;
        padding: 7px 20px;
        cursor: grab;
        .cate_icon{
            width: 42px;
            min-width: 42px;
            height: 42px;
            // background-color: #f9f9f9;
            border-radius: 6px;
            margin-right: 20px;
            object-fit: cover;
        }
        .category_name{
            font-family: Inter-Bold, Inter;
            font-weight: bold;
            font-size: 20px;
            color: #222222;
            line-height: 24px;
            text-align: left;
            word-break: break-word;
        }
    }
}

.category_card[aria-pressed='true'] {
    background: rgba(223, 0, 36, 0.04) !important;
    border: 1px solid rgba(223, 0, 36, 0.3);
    border-radius: 4px;
}
.catr_detailMain{
    .category_info{
        .cate_icon{
            width: 90px;
            height: 90px;
            background: #FFFFFF;
            border-radius: 8px 8px 8px 8px;
            border: 1px solid #E4E4E4;
        }
        .catename_box{
            font-family: Inter-Bold, Inter;
            font-weight: bold;
            font-size: 24px;
            color: #222222;
            line-height: 32px;
            text-align: left;
            margin: 0 20px;
        }
        .edit_icon{
            width: 16px;
            height: 16px;
            background: url('../../../assets/img/edit.png') no-repeat;
            background-size: contain;
            cursor: pointer;
        }
        .questionNum_box{
            font-family: PlusJakartaSans-SemiBold;
            font-weight: 500;
            font-size: 16px;
            color: #222222;
            line-height: 24px;
            text-align: left;
            margin: 30px 0 20px 0;
            .addPrizeBtn{
                width: 160px;
                height: 32px;
                background: #FFFFFF;
                border-radius: 6px 6px 6px 6px;
                border: 1px solid #FF7957;
                font-family: Inter-Medium, Inter;
                font-weight: 500;
                font-size: 14px;
                color: #FF7957;
                cursor: pointer;
                user-select: none;
            }
        }
        .question-box{
            width: 100%;
            background: #FFFFFF;
            border-radius: 10px 10px 10px 10px;
            border: 1px solid #E4E4E4;
            padding: 0 30px;
            .question-item{
                padding: 20px 0;
                border-bottom: 1px solid #E4E4E4;
                font-family: Inter-SemiBold;
                font-weight: normal;
                font-size: 16px;
                color: #222222;
                line-height: 24px;
                border-bottom: 1px solid #E4E4E4;
                &:last-child{
                    border: none;
                }
            }
            .oprationBox{
                margin-left: 40px;
            }
        }
    }
}
.margL8{
    margin-right: 8px;
}
.addOption_text{
    width: 88px;
    font-family: Inter-SemiBold, Inter;
    font-weight: normal;
    font-size: 14px;
    color: #FF7957;
    line-height: 20px;
    text-align: left;
    user-select: none;
    cursor: pointer;
    margin-top: 16px;
}
.optionDel_text{
    width: 38px;
    font-family: Inter-Medium, Inter;
    font-weight: 500;
    font-size: 12px;
    color: #F30A0A;
    line-height: 16px;
    user-select: none;
    cursor: pointer;
}
// 
.statistics-contentBox{
    .statisticsTip{
        font-family: Inter-SemiBold;
        font-weight: normal;
        color: #222222;
        font-size: 14px;
    }
    .ministis_tip{
        font-size: 12px;
        font-family: Inter-Medium, Inter;
        font-weight: 500;
        color: #9D9D9D;
        line-height: 16px;
        font-style: normal;
        margin: 20px 0 10px 0;
    }
    .questionChart_box{
        width: 100%;
        background: #F7F7F7;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #E4E4E4;
        padding: 20px;
        .question_name{
            font-family: Inter-SemiBold;
            font-weight: normal;
            color: #222222;
            font-size: 16px;
            margin-bottom: 20px;
        }
        .lineChart-box{
            width: 100%;    
            height: 48px;
            background: rgba(81,135,239,0.2);
            border-radius: 6px 6px 6px 6px;
            border: 1px solid #E4E4E4;
            margin-bottom: 16px;
            padding: 12px 16px;
            position: relative;
            z-index: 1;
            &:last-child{
                margin: 0;
            }
            .chart-text{
                position: relative;
                z-index: 3;
                font-family: Inter-Medium, Inter;
                font-weight: 500;
                font-size: 14px;
                // color: #FFFFFF;
                color: #002264;
                text-align: left;
                user-select: none;
            }
            .chart-num{
                position: relative;
                z-index: 3;
                user-select: none;
                margin-left: auto;
                font-family: PlusJakartaSans-Medium;
                font-weight: 500;
                font-size: 14px;
                color: #222222;
            }
            .blueChart_box{
                max-width: 378px;
                position: absolute;
                left: 0;
                top: 0;
                background: #5187EF;
                height: 48px;
                border-radius: 6px 0 0 6px;
            }
        }
    }
    .genderBox{
        width: 200px;
        height: 60px;
        border-radius: 8px 8px 8px 8px;
        border: 1px solid #E4E4E4;
        padding: 12px;
        &:first-child{
            margin-right: 16px;
        }
        .female-progress{
            margin-right: 12px;
            .ant-progress-circle-trail{
                stroke:rgba(62, 204, 146, .2);
            }
        }
        .male-progress{
            margin-right: 12px;
            .ant-progress-circle-trail{
                stroke:rgba(81, 135, 239, .1);
            }
        }
        .gender-text{
            font-family: Inter-Bold, Inter;
            font-weight: bold;
            font-size: 12px;
            color: #3ECC92;
            line-height: 16px;
            text-align: left;
        }
        .male-color{
            color: #5187EF;
        }
    }
    .percent_box{
        align-items: flex-start;
        font-family: Inter-Bold, Inter;
        font-weight: bold;
        font-size: 12px;
        color: #3ECC92;
        line-height: 16px;
        text-align: center;
        .minipercent_tip{
            font-family: Inter-SemiBold, Inter;
            font-weight: normal;
            font-size: 8px;
            color: #3ECC92;
            line-height: 12px;
            text-align: center;
            font-style: normal;
        }
        &.male-color{
            color: #5187EF;
            .minipercent_tip{
                color: #5187EF;
            }
        }
    }
}
.chart-text{
    width:calc(378px - 80px) ;
    .pointNew_box{
        min-width: max-content;
        margin-left: 5px;
    }
}