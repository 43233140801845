.prize_main {
    h2 {
        font-size: 32px;
        font-family: var(--primary-semiBold-font);
        font-weight: normal;
        color: #151515;
        line-height: 38px;
    }
    .workout_top {
        margin: 60px 0 30px 0;
        .space15 {
            width: 15px;
            height: 100%;
        }
        .total-box{
            font-family: Inter-Medium, Inter;
            font-weight: 500;
            font-size: 14px;
            color: #222222;
            margin-left: auto;
            .price-tip{
                font-family: Inter-Bold;
                font-weight: bold;
                font-size: 20px;
                margin-left: 10px;
            }
        }
    }
}
.prize_detailMain{
    .memberList_box{
        width: max-content;
        height: 48px;
        border-radius: 8px 8px 8px 8px;
        margin: 50px 0 30px 0;
        background: #F5F5F5;
        .member-item{
            font-family: Inter-SemiBold, Inter;
            font-weight: normal;
            font-size: 16px;
            color: #9D9D9D;
            padding: 0 45px;
            line-height: 48px;
            cursor: pointer;
            user-select: none;        
            background: #F5F5F5;
            border-radius: 8px 8px 8px 8px;
            &.active{
                background-color: #FF7957;
                color: #fff;
            }
        }
    }
    .memberPoint{
        font-family: Inter-Bold, Inter;
        font-weight: bold;
        font-size: 24px;
        color: #222222;
        line-height: 32px;
        text-align: left;
    }
    .prizeNum_box{
        font-family: PlusJakartaSans-Medium;
        font-weight: 500;
        font-size: 16px;
        color: #222222;
        line-height: 24px;
        text-align: left;
        margin: 6px 0 20px 0;
    }
}

.addPrizeBtn{
    width: 120px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #FF7957;
    font-family: Inter-Medium, Inter;
    font-weight: 500;
    font-size: 14px;
    color: #FF7957;
    cursor: pointer;
    user-select: none;
}
.prize_modeCheck{
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    font-size: 14px;
    color: #222222;
    line-height: 20px;
    text-align: left;
    cursor: pointer;
    &:last-child{    
        margin-left: 160px;
    }
    .prmar10{
        margin-left: 10px;
        user-select: none;
    }
}
.pzmarttop{
    margin-top: 10px;
}
.memberPrizeAdd_item{
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #E4E4E4;
    font-family: Inter-Regular, Inter;
    font-weight: 400;
    font-size: 14px;
    color: #222222;
    padding: 14px 16px;
    margin-bottom: 8px;
    .del_icon{
        width: 22px;
        height: 22px;
        background: url('../../../assets/img/delRound.png') no-repeat;
        background-size: contain;
        cursor: pointer;
        margin-left: auto;  
    }
    .prizename_box{
        width: calc(100% - 40px);
    }
}
.addMemberPrize_btn{
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #E4E4E4;
    cursor: pointer;
    user-select: none;
    font-family: Inter-SemiBold, Inter;
    font-weight: normal;
    font-size: 14px;
    color: #FF7957;
}
// v1.1
.member-opBox{
    .member-opLabel{
        font-family: Inter-Regular, Inter;
        font-weight: 400;
        font-size: 14px;
        color: #222222;
        line-height: 20px;
        margin-bottom: 3px;
        opacity: .7;
    }
    .member-opTit{
        margin: 10px 0 5px 0;
        opacity: 1;
    }
}
.prize-allocationTit{
    font-family: Inter-SemiBold;
    font-weight: 600;
    font-size: 20px;
    color: #222222;
    line-height: 24px;
    margin-top: 40px;
}