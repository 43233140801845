.clear:after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    clear: both;
}
ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
h1,
h2,
h3,
h4,
h5,
p {
    margin: 0;
    padding: 0;
    word-break: break-word;
}
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body,
html {
    font-family: var(--primary-light-font);
}
.page_mainBox {
    padding: 60px 50px;
    // min-width: 1190px;
}
.ellipsis {
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
}
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 0 0 0 0;
}

::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
    border-radius: 6px;
}